<template>
    <BaseModal
        v-model="open"
        size="md">
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-danger-100 sm:mx-0 sm:h-10 sm:w-10">
                <Icon
                    name="heroicons:exclamation-triangle"
                    class="h-6 w-6 text-danger-600"
                    aria-hidden="true" />
            </div>
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900">
                    {{ title }}
                </DialogTitle>
                <div class="mt-2">
                    <p class="text-sm text-gray-500 dark:text-gray-200">
                        {{ text }}
                    </p>
                </div>
            </div>
        </div>
        <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
                type="button"
                class="inline-flex w-full justify-center rounded-md bg-danger-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-danger-500 sm:ml-3 sm:w-auto"
                @click="$emit('delete'); open = false">
                {{ $t('delete') }}
            </button>
            <button
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                @click="open = false">
                {{ $t('abort') }}
            </button>
        </div>
    </BaseModal>
</template>

<script setup>

const props = defineProps({
    title: {
        type: String,
        required: true
    },
    text: {
        type: String,
        required: true
    },
    modelValue: {
        type: Boolean,
        required: true
    }
})

const emits = defineEmits(['update:modelValue', 'delete'])

const open = computed({
    get: () => props.modelValue,
    set: (value) => emits('update:modelValue', value)
})
</script>